<template>
    <form
        method="post"
        class="grid grid-cols-12 gap-6 px-2"
        :class="{ 'disabled-form': loading }"
        @submit.prevent="saveForm"
    >
        <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
            <Preloader />
        </div>

        <div class="relative col-span-12">
            <label for="form-title" class="form-label">
                Заголовок
                <sup v-if="v$.form.title.required" class="text-theme-6">*</sup>
            </label>
            <label v-if="v$.form.title.$errors.length" for="form-title" class="form-input-text-error">
                {{ getOverrideMessage(v$.form.title.$errors[0].$message) }}
            </label>
            <input
                id="form-title"
                v-model="form.title"
                type="text"
                class="form-control"
                :class="{ 'form-input-error': v$.form.title.$errors.length }"
                autocomplete="off"
            />
        </div>

        <div class="col-span-12">
            <label class="form-label">Описание</label>
            <CustomWyswig v-model="form.description" />
        </div>

        <div class="col-span-12">
            <label class="form-label">Изображения</label>
            <UploadMultiImageNew v-model="form.images" v-model:delete="form.images_delete" />
        </div>

        <div class="col-span-12 flex justify-end">
            <button type="button" class="btn btn-secondary mr-2 w-24" @click.prevent="$emit('dataSaved')">
                Отмена
            </button>
            <button type="submit" class="btn btn-primary">Сохранить</button>
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { errorResponse, formPreparation } from '@/mixins/form/form-mixin';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import Preloader from '@/components/preloader/Main';
import UploadMultiImageNew from '@/components/upload-image/UploadMultiImageNew';
import { helper as $h } from '@/utils/helper';

export default {
    name: 'CrudCabinType',
    components: { UploadMultiImageNew, Preloader, CustomWyswig },
    mixins: [errorResponse, formPreparation],
    props: {
        cabinTypeId: {
            type: Number,
            required: true,
        },
    },
    emits: ['dataSaved'],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            loading: false,
            form: {
                title: null,
                description: null,
                images: [],
                images_delete: [],
            },
            imagesPreview: null,
        };
    },
    validations() {
        return {
            form: {
                title: { required },
            },
        };
    },
    created() {
        this.fetchCabinCategory();
    },
    methods: {
        fetchCabinCategory() {
            this.loading = true;

            this.axios
                .get(`/ships/${this.$route.params.id}/cabin-types/${this.cabinTypeId}/edit`)
                .then((response) => {
                    const data = response.data.data;
                    for (const [key, value] of Object.entries(data)) {
                        this.form[key] = value;
                    }

                    this.imagesPreview = data.images;
                    this.form.images = data.images;
                    this.loading = false;
                })
                .catch((ex) => {
                    console.log('cant fetch cabin category: ' + ex);
                    this.loading = false;
                    this.$router.push('/error-page');
                });
        },
        async saveForm() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            const form = this.formPreparation({ imagesAsObject: true });
            this.axios
                .post(`/ships/${this.$route.params.id}/cabin-types/${this.cabinTypeId}`, form, {
                    params: {
                        _method: 'patch',
                    },
                })
                .then((res) => {
                    this.$emit('dataSaved', true);
                    this.$notify(res.data.message);
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style scoped></style>
