<template>
    <form method="post" class="grid grid-cols-12 gap-6 px-2" @submit.prevent="saveForm">
        <div class="col-span-12">
            <label for="title" class="form-label">Название <sup>*</sup></label>
            <input id="title" v-model="form.title" type="text" class="form-control" required />
        </div>

        <div class="col-span-12">
            <label for="index" class="form-label">Сортировка</label>
            <input id="index" v-model="form.index" type="number" max="100" class="form-control" />
        </div>

        <div class="col-span-12">
            <label for="index" class="form-label">Схема <sup>*</sup></label>
            <UploadImageNew v-model="form.scheme_url" />
        </div>

        <div class="col-span-12">
            <label for="index" class="form-label">Схема в svg</label>
            <UploadImageNew v-model="form.scheme_svg" :accept-extensions="image / svg + xml">
                <template #text="">
                    <span class="btn btn-primary inline-block">Загрузите svg схему</span>
                </template>
            </UploadImageNew>
        </div>

        <div class="col-span-12 flex justify-end">
            <button type="button" class="btn btn-secondary mr-2 w-24" @click.prevent="$emit('dataSaved')">
                Отмена
            </button>
            <button type="submit" class="btn btn-primary">Сохранить</button>
        </div>
    </form>
</template>

<script>
import { errorResponse, formPreparation } from '@/mixins/form/form-mixin';
import { decksMixin } from '@/mixins/form/decks-mixin';
import UploadImageNew from '@/components/upload-image/UploadImageNew';

export default {
    name: 'CreateDeck',
    components: { UploadImageNew },
    mixins: [errorResponse, formPreparation, decksMixin],
    methods: {
        saveForm() {
            const form = this.formPreparation();

            this.axios
                .post(`/ships/${this.$route.params.id}/decks`, form)
                .then((data) => {
                    this.$emit('dataSaved', true);
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
    },
};
</script>

<style scoped>
.form-label {
    font-weight: 700;
}
</style>
